@import "./color.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;500;700;900&display=swap");
html,
body,
header,
nav,
main,
aside,
section,
article,
figure,
figcaption,
footer,
div,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
ul,
li,
ol,
i,
address,
strong,
form,
fieldset,
input,
legend,
button,
summary,
details,
select,
option,
label,
img,
svg {
  margin: 0;
  padding: 0;
  border: none;
  color: var(--text-800);
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.03vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  word-break: keep-all;
}
::selection,
::-moz-selection {
  background-color: var(--theme-500);
  color: #fff;
}
:root {
  --inner-padding: 2rem;
}

header,
nav,
main,
aside,
section,
article,
footer {
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  font-weight: 700;
}
html {
  font-size: 0.625em;
  background-color: var(--depth-100);
}
a {
  text-decoration: none;
  color: inherit;
  cursor: default;
}
i {
  font-style: normal;
}
button {
  border-radius: 0;
  background-color: transparent;
  cursor: default;
}
ul,
li {
  list-style: none;
}
img {
  vertical-align: middle;
}
summary {
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }
}

*:focus {
  outline: none;
}
*::-webkit-scrollbar {
  width: 0.5rem;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.25rem;
}
.line-break {
  display: block;
}

@media screen and (min-width: 768px) {
  :root {
    --inner-padding: 5rem;
  }
}

@media screen and (min-width: 1024px) {
  a,
  button {
    cursor: pointer;
  }
}
