/*theme - 푸른 하늘의 랩소디*/

:root {
  --text-900: hsl(210, 29%, 10%);
  --text-800: hsl(210, 20%, 20%);
  --text-700: hsl(210, 20%, 30%);
  --text-600: hsl(210, 20%, 40%);
  --text-500: hsl(210, 20%, 50%);
  --text-400: hsl(210, 20%, 60%);
  --text-300: hsl(210, 20%, 70%);
  --text-200: hsl(210, 20%, 80%);
  --text-100: hsl(210, 20%, 90%);

  --theme-900: hsl(216, 89%, 20%);
  --theme-800: hsl(216, 89%, 28%);
  --theme-700: hsl(216, 89%, 36%);
  --theme-600: hsl(216, 89%, 44%);
  --theme-500: hsl(216, 89%, 52%);
  --theme-400: hsl(216, 89%, 60%);
  --theme-300: hsl(216, 89%, 68%);
  --theme-200: hsl(216, 89%, 76%);
  --theme-100: hsl(216, 89%, 84%);

  --depth-900: hsl(265, 20%, 32%);
  --depth-800: hsl(265, 20%, 40%);
  --depth-700: hsl(265, 20%, 48%);
  --depth-600: hsl(265, 20%, 56%);
  --depth-500: hsl(265, 20%, 64%);
  --depth-400: hsl(265, 20%, 72%);
  --depth-300: hsl(265, 20%, 80%);
  --depth-200: hsl(265, 20%, 88%);
  --depth-100: hsl(265, 20%, 96%);
}

[data-theme="dark"] {
  --depth-900: hsl(265, 50%, 16%);
  --depth-800: hsl(265, 50%, 24%);
  --depth-700: hsl(265, 50%, 32%);
  --depth-600: hsl(265, 50%, 40%);
  --depth-500: hsl(265, 50%, 48%);
  --depth-400: hsl(265, 50%, 56%);
  --depth-300: hsl(265, 50%, 64%);
  --depth-200: hsl(265, 50%, 72%);
  --depth-100: hsl(265, 50%, 80%);
}
